import React from "react";
import { ThemeProvider } from "../../Utils/themeProvider";
import CustomText from "../Components/CustomText";
import "../stylesheet.css";
import "../font.css";

export default function productPage() {
  return (
    <ThemeProvider>
      <CustomText type="titoloH1">Pagina Prodotti</CustomText>
    </ThemeProvider>
  );
}
