import { createTheme } from "@material-ui/core/styles";
import { ColorType, CustomTextType } from "./themeTypes";

const BLACK = "#242422";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    colors: ColorType;
    customText: CustomTextType;
  }
  interface ThemeOptions {
    colors: ColorType;
    customText: CustomTextType;
  }
}

export const theme = (darkMode: "dark" | "light", isMobile?: boolean) =>
  createTheme({
    palette: {
      type: darkMode,
      primary: {
        main: "#FFFFFF",
      },
      secondary: {
        main: "#000000",
      },
    },
    colors: {
      white: "#FFFFFF",
      entertainment: "#F2E61A",
      black: BLACK,
    },
    customText: {
      titoloH1: {
        fontSize: "clamp(40px, 4.375vw, 70px)",
        fontFamily: "Agrandir",
        fontWeight: 900,
        color: BLACK,
      },
      payoff: {
        fontSize: "clamp(18px, 1.75vw, 28px)",
        fontFamily: "Agrandir-Wide",
        color: BLACK,
        fontWeight: 500,
      },
      h2Sezioni: {
        fontSize: "clamp(38px, 3.75vw, 60px)",
        fontFamily: "Agrandir",
        color: BLACK,
        fontWeight: 900,
      },
      h2Services: {
        fontSize: "clamp(20px, 2vw, 32px)",
        fontFamily: "Agrandir-Wide",
        color: BLACK,
        fontWeight: 900,
      },
      h3People: {
        fontSize: "clamp(35px, 3.625vw, 58px)",
        fontFamily: "Agrandir-Wide",
        color: BLACK,
        fontWeight: 900,
      },
      h3Sezioni: {
        fontSize: "clamp(20px, 2vw, 32px)",
        fontFamily: "Agrandir-Wide",
        color: BLACK,
        fontWeight: 900,
      },
      titoloH4: {
        fontSize: "clamp(18px, 4.375vw, 24px)",
        fontFamily: "Agrandir-Wide",
        color: BLACK,
        fontWeight: 500,
      },
      paragrafo: {
        fontSize: "clamp(18px, 1.75vw, 22px)",
        fontFamily: "Agrandir",
        color: BLACK,
        fontWeight: "normal",
      },
      payoff2: {
        fontSize: 16,
        color: BLACK,
        fontFamily: "Salted",
      },
    },
    typography: {
      fontFamily: ["Agrandir", "Agrandir-Wide", "Salted"].join(","),
      button: {
        fontSize: 14,
        lineHeight: "normal",
        fontWeight: "bold",
        fontFamily: "Agrandir",
      },
    },
  });
